import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import FormTwo from "../component/contact/FormTwo";

const TermsOfUse = () => {
  return (
    <>
      <SEO title="Terms Of Use" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Terms Of Use" page="Terms Of Use" />

        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="section-title">
                  <h5 className="title">
                    This Terms Of Use was published on September 15th,
                    2023.
                  </h5>
                </div>
                <div className="privacy-policy-content">
                  <h4>Acceptance of Terms</h4>
                  <p>
                    Welcome to Uniqum Software ("we," "our," or "us").
                    By accessing or using our website, products, or
                    services, you agree to comply with and be bound by
                    these Terms of Use. If you do not agree to these
                    terms, please do not use our services.
                  </p>
                  <h4>Use of Our Services</h4>
                  <p>
                    You may use our services for lawful purposes and
                    in accordance with these Terms of Use. You agree
                    not to:
                  </p>
                  <ul>
                    <li>
                      Use our services in any way that violates
                      applicable laws or regulations.
                    </li>
                    <li>
                      Use our services to engage in any fraudulent or
                      unlawful activities.
                    </li>
                    <li>
                      Interfere with or disrupt the operation of our
                      services or the servers and networks connected
                      to them.
                    </li>
                    <li>
                      Attempt to gain unauthorized access to our
                      services, user accounts, or computer systems.
                    </li>
                  </ul>
                  <h4>Intellectual Property</h4>
                  <p>
                    All content, materials, trademarks, and
                    intellectual property on our website and services
                    are owned by Uniqum Software and are protected by
                    copyright and other intellectual property laws.
                    You may not reproduce, modify, distribute, or
                    create derivative works from our content without
                    our explicit consent.
                  </p>
                  <h4>Privacy Policy</h4>
                  <p>
                    Your use of our services is also governed by our
                    Privacy Policy. By using our services, you consent
                    to the collection, use, and disclosure of your
                    personal information as described in our Privacy
                    Policy.
                  </p>
                  <h4>Limitation of Liability</h4>
                  <p>
                    To the fullest extent permitted by applicable law,
                    Uniqum Software and its affiliates, directors,
                    officers, employees, and agents shall not be
                    liable for any indirect, incidental,
                    consequential, or punitive damages, or any loss of
                    profits or revenues arising out of your use of our
                    services.
                  </p>
                  <h4>Changes to Terms of Use</h4>
                  <p>
                    We reserve the right to update these Terms of Use
                    at any time. Changes will be effective upon
                    posting on our website. It is your responsibility
                    to review these terms periodically to stay
                    informed of any changes.
                  </p>
                  <h4>Termination</h4>
                  <p>
                    We reserve the right to terminate your access to
                    our services at our sole discretion, with or
                    without cause, and without notice.
                  </p>
                  <h4>Contact Us</h4>
                  <p className="mb--20">
                    If you have questions, concerns, or requests
                    regarding this Privacy Policy, please contact us
                    at:
                  </p>
                  <div className="contact-form-box shadow-box mb--30">
                    <h3 className="title">Contact form</h3>
                    <FormTwo />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default TermsOfUse;
