import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SEO from "../common/SEO";
import FormTwo from "../component/contact/FormTwo";

const PrivacyPolicy = () => {
  return (
    <>
      <SEO title="Privacy Policy" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="Privacy Policy" page="Privacy Policy" />
        <div className="section-padding privacy-policy-area">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="privacy-policy-content">
                  <div className="section-title">
                    <h5 className="title">
                      This Privacy policy was published on September
                      15th, 2023.
                    </h5>
                  </div>
                  <h4>Introduction</h4>
                  <p>
                    Welcome to Uniqum Software ("we," "our," or "us").
                    We are committed to protecting your privacy and
                    ensuring that your personal information is handled
                    responsibly. This Privacy Policy outlines how we
                    collect, use, disclose, and safeguard your
                    personal information.
                  </p>
                  <h4>Information We Collect</h4>
                  <p>
                    We may collect various types of information from
                    you, including:
                    <ul>
                      <li>
                        Personal Information: Information that can be
                        used to identify you, such as your name, email
                        address, phone number, and other contact
                        details.
                      </li>
                      <li>
                        Non-Personal Information: Information that
                        does not personally identify you, such as your
                        IP address, browser type, device information,
                        and website usage data.
                      </li>
                    </ul>
                  </p>
                  <h4>How We Use Your Information</h4>
                  <ul>
                    <li>
                      To provide and improve our services, products,
                      and website functionality.
                    </li>
                    <li>
                      To communicate with you, respond to your
                      inquiries, and provide customer support.
                    </li>
                    <li>
                      Whether you’re kicking off a new campaign.
                    </li>
                    <li>
                      To send you important updates, newsletters, and
                      marketing materials with your consent.
                    </li>
                    <li>
                      To comply with legal obligations and protect our
                      rights and interests.
                    </li>
                  </ul>
                  <h4>Disclosure of Your Information</h4>
                  <p className="mb--20">
                    We may share your information with third parties
                    in the following situations:
                  </p>
                  <ul>
                    <li>With your consent.</li>
                    <li>
                      To comply with legal requirements, including
                      subpoenas, court orders, or other legal
                      processes.
                    </li>
                    <li>
                      To protect our rights, privacy, safety, or
                      property, and that of our affiliates, customers,
                      or the public.
                    </li>
                    <li>
                      In connection with the sale, merger,
                      acquisition, or reorganization of all or part of
                      our company.
                    </li>
                  </ul>

                  <h4>Security Measures</h4>
                  <p>
                    We employ industry-standard security measures to
                    protect your personal information from
                    unauthorized access, disclosure, alteration, or
                    destruction. However, no method of data
                    transmission over the internet or electronic
                    storage is completely secure, and we cannot
                    guarantee absolute security.
                  </p>
                  <h4>Your Choices and Rights</h4>
                  <p>
                    You have certain rights regarding your personal
                    information, including the right to access,
                    correct, delete, or restrict its use. To exercise
                    these rights or make inquiries about your data,
                    please contact us using the contact information
                    provided below.
                  </p>
                  <h4>Changes to this Privacy Policy</h4>
                  <p className="mb--20">
                    We reserve the right to update this Privacy Policy
                    to reflect changes to our practices or for legal
                    or regulatory reasons. We will notify you of any
                    significant changes through our website or other
                    appropriate means.
                  </p>

                  <h4>Contact Us</h4>
                  <p className="mb--20">
                    If you have questions, concerns, or requests
                    regarding this Privacy Policy, please contact us
                    at:
                  </p>
                  <div className="contact-form-box shadow-box mb--30">
                    <h3 className="title">Contact form</h3>
                    <FormTwo />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default PrivacyPolicy;
