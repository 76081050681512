import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderOne from "../common/header/HeaderOne";
import SEO from "../common/SEO";
import BreadCrumbOne from "../elements/breadcrumb/BreadCrumbOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ColorSwitcher from "../elements/switcher/ColorSwitcher";
import SectionTitle from "../elements/section-title/SectionTitle";
import FaqOne from "../component/faq/FaqOne";

const FAQ = () => {
  return (
    <>
      <SEO title="FAQ" />
      <ColorSwitcher />
      <main className="main-wrapper">
        <HeaderOne />
        <BreadCrumbOne title="FAQ" page="FAQ" />
        <div className="section section-padding">
          <div className="container">
            <SectionTitle
              subtitle="Get the Answers You Need"
              title="Frequently Asked Question"
              description="Your Questions, Our Answers: Unveiling Insights"
              textAlignment="mb-0"
              textColor=""
            />
          </div>
          <ul className="shape-group-3 list-unstyled">
            <li className="shape shape-2">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/others/bubble-4.png"
                }
                alt="shape"
              />
            </li>
          </ul>
        </div>
        <FaqOne />
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default FAQ;
