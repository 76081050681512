import React from "react";
import FormOne from "../contact/FormOne";

const AboutOne = () => {
  return (
    <section className="section section-padding-equal bg-color-light">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-us">
              <div className="section-heading heading-left mb-0">
                <span className="subtitle">About Us</span>
                <h2 className="title mb--40">
                  We're your one-stop solution for CI/CD
                </h2>
                <p>
                  At UNIQUM SOFTWARE, our extensive experience spans
                  across diverse technology domains, making us a
                  trusted partner for your digital ventures. With
                  proficiency in web and mobile development, backend
                  systems, and cloud platforms, we bring a
                  comprehensive skill set to the table. With our
                  experience, your software journey is set for
                  success.
                </p>
                <p>
                  Trust us to navigate the intricacies of modern
                  technology, future-proofing your projects for
                  success.
                </p>
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="contact-form-box">
              <h3 className="title">
                Get a free Keystroke quote now
              </h3>
              <FormOne />
            </div>
          </div>
        </div>
      </div>
      <ul className="shape-group-6 list-unstyled">
        <li className="shape shape-1">
          <img src={"/images/others/bubble-7.png"} alt="Bubble" />
        </li>
        <li className="shape shape-2">
          <img src={"/images/others/line-4.png"} alt="line" />
        </li>
        <li className="shape shape-3">
          <img src={"/images/others/line-5.png"} alt="line" />
        </li>
      </ul>
    </section>
  );
};

export default AboutOne;
