import React, { useEffect, useRef, useState } from "react";
import { contactUs } from "../../queries/queries.service";
import { Result } from "./FormOne";

const FormTwo = () => {
  const form = useRef();

  const [result, showresult] = useState("");

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      let formData = new FormData(e.target);
      const data = {
        email: formData.get("contact-email"),
        name: formData.get("contact-name"),
        phone: formData.get("contact-phone"),
        message: formData.get("contact-message"),
      };
      await contactUs(data);
      showresult("success");
      form.current.reset();
    } catch (error) {
      showresult("danger");
    }
  };

  useEffect(() => {
    if (result)
      setTimeout(() => {
        showresult("");
      }, 5000);
  }, [result]);

  return (
    <form
      ref={form}
      onSubmit={sendEmail}
      className="axil-contact-form"
    >
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          className="form-control"
          name="contact-name"
          required
          placeholder="Please grace us with your beautiful name"
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          name="contact-email"
          required
          placeholder="Share your email magic with us"
        />
      </div>
      <div className="form-group">
        <label>Phone</label>
        <input
          type="tel"
          className="form-control"
          name="contact-phone"
          placeholder="Drop your digits here"
          required
        />
      </div>
      <div className="form-group mb--40">
        <label>How can we help you?</label>
        <textarea
          className="form-control"
          name="contact-message"
          rows="4"
          placeholder="Leave your message here..."
        />
      </div>
      <div className="form-group">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
        >
          Contact
        </button>
      </div>
      <div className="form-group">
        {result ? <Result type={result} /> : null}
      </div>
    </form>
  );
};

export default FormTwo;
