import React from "react";
import TechnologiesData from "../../data/technologies/TechnologiesData.json";

const FeatureData = TechnologiesData;
export default function Technologies() {
  return (
    <div
      className="section section-padding bg-color-dark splash-features"
      id="splsh-features"
    >
      <div className="container">
        <div className="section-heading heading-light-left">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h2 className="title">
                We use impressive technologies
              </h2>
            </div>
            <div className="col-lg-5 col-md-8">
              <p>
                Empowering Innovation Through State-of-the-Art
                Technologies
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          {FeatureData.slice(0, 9).map((data) => (
            <div className="col-xl-4 col-md-6" key={data.id}>
              <div className="services-grid">
                <div className="thumbnail">
                  <img src={data.icon} alt="icon" />
                </div>
                <div className="content">
                  <h5
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: data.title,
                    }}
                  ></h5>
                  <p>{data.para}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ul className="list-unstyled shape-group-10">
        <li className="shape shape-1">
          <img src={"/images/others/circle-1.png"} alt="Circle" />
        </li>
        <li className="shape shape-2">
          <img src={"/images/others/line-3.png"} alt="Circle" />
        </li>
        <li className="shape shape-3">
          <img src={"/images/others/bubble-5.png"} alt="Circle" />
        </li>
      </ul>
    </div>
  );
}
