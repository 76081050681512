import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";

const Datas = [
  {
    id: 1,
    title: "Proven Expertise",
    para: "Seasoned professionals with diverse experience and extensive tech knowledge.",
  },
  {
    id: 2,
    title: "Cutting-Edge Technology",
    para: "Stay updated on cutting-edge technologies for innovative solutions.",
  },
  {
    id: 3,
    title: "Tailored Solutions",
    para: "Custom software solutions aligned with your unique goals.",
  },
  {
    id: 4,
    title: "Proven Track Record",
    para: "Impressive track record of on-time, on-budget project delivery.",
  },
  {
    id: 5,
    title: "Client-Centric Approach",
    para: "Transparent communication and collaboration throughout.",
  },
  {
    id: 6,
    title: "Scalability and Support",
    para: "Ongoing maintenance and scalable solutions for business growth.",
  },
];

const AboutThree = () => {
  return (
    <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
      <div className="container">
        <SectionTitle
          subtitle="Unlock Your Digital Potential with Uniqum Software"
          title="Why should you work with us?"
          description="In a digital age, partnering with Uniqum Software is your key to success. Our team of seasoned professionals, armed with extensive industry experience and in-depth technological know-how, is dedicated to bringing your digital vision to life. Here's why you should choose us"
          textAlignment="heading-left heading-light-left mb--100"
          textColor=""
        />

        <div className="row">
          {Datas.map((data) => (
            <div className="col-lg-4" key={data.id}>
              <div className="about-quality">
                <h3 className="sl-number">{data.id}</h3>
                <h5 className="title">{data.title}</h5>
                <p>{data.para}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ul className="list-unstyled shape-group-10">
        <li className="shape shape-1">
          <img
            src={
              process.env.PUBLIC_URL + "/images/others/circle-1.png"
            }
            alt="Circle"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-3.png"}
            alt="Circle"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={
              process.env.PUBLIC_URL + "/images/others/bubble-5.png"
            }
            alt="Circle"
          />
        </li>
      </ul>
    </div>
  );
};

export default AboutThree;
