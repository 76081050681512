import React from "react";
import { Link } from "react-router-dom";
const style = { width: 80, height: 80 };

const Logo = ({ limage, dimage, simage }) => {
  return (
    <Link to={"/"}>
      <img
        className="light-version-logo"
        src={limage}
        alt="logo"
        style={style}
      />
      <img
        className="dark-version-logo"
        src={dimage}
        alt="logo"
        style={style}
      />
      <img
        className="sticky-logo"
        src={simage}
        alt="logo"
        style={style}
      />
    </Link>
  );
};

export default Logo;
