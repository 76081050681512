import React, { useEffect, useRef, useState } from "react";
import Alert from "react-bootstrap/Alert";
import { contactUs } from "../../queries/queries.service";

export const Result = ({ type = "success" }) => {
  const isSuccess = type === "success";
  return (
    <Alert
      variant={type}
      className={isSuccess ? "success-msg" : "error-msg"}
    >
      {isSuccess
        ? "Your Message has been successfully sent."
        : "Something went wrong. Please try again or contact us directly."}
    </Alert>
  );
};

const FormOne = () => {
  const form = useRef();

  const [result, showresult] = useState("");

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      let formData = new FormData(e.target);
      const data = {
        email: formData.get("contact-email"),
        name: formData.get("contact-name"),
        phone: formData.get("contact-phone"),
        message:
          "This person should be contacted, he texted from a free quote form.",
      };
      await contactUs(data);
      showresult("success");
      form.current.reset();
    } catch (error) {
      showresult("danger");
    }
  };

  useEffect(() => {
    if (result)
      setTimeout(() => {
        showresult("");
      }, 5000);
  }, [result]);

  return (
    <form
      ref={form}
      onSubmit={sendEmail}
      className="axil-contact-form"
    >
      <div className="form-group">
        <label>Name</label>
        <input
          type="text"
          className="form-control"
          name="contact-name"
          placeholder="Please grace us with your beautiful name"
          required
        />
      </div>
      <div className="form-group">
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          name="contact-email"
          placeholder="Share your email magic with us"
          required
        />
      </div>
      <div className="form-group mb--40">
        <label>Phone</label>
        <input
          type="tel"
          className="form-control"
          name="contact-phone"
          placeholder="Drop your digits here"
          required
        />
      </div>
      <div className="form-group">
        <button
          type="submit"
          className="axil-btn btn-fill-primary btn-fluid btn-primary"
          name="submit-btn"
        >
          Get Free Quote
        </button>
      </div>
      <div className="form-group">
        {result ? <Result type={result} /> : null}
      </div>
    </form>
  );
};

export default FormOne;
